.layout-main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 2rem 2rem 4rem;
    transition: margin-left $transitionDuration;
}

.layout-main {
    flex: 1 1 auto;
}
