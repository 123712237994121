body {
  background: #060e1b;
}

canvas{
  position:absolute;
  left:0;
  top:0;
  z-index:-1;
}
div{
  z-index:0;
  /* Not sure why this is here, but it's applying this left and top shift to every div on the page. Bad. Removing. */
  /*left:12px;
  top:10px;*/
}