/* You can add global styles to this file, and also import other style files */

html,
body {
    margin: 0px;
    background-image: url('./assets/images/Community_owned_bg.jpg');
    background-color: #000000 !important;
    background-size: cover;
    padding: 0px;
    color: white;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    position: relative;
}

@import "assets/layout/layout.scss";
@import "assets/layout/layout.css";

@import "../node_modules/primeicons/primeicons.css";



@font-face {
    font-family: "Kallisto W05 Thin";
    src: url("assets/fonts/6034527/9dd7deec-4390-4a97-81b7-1b7a5d200eed.woff2") format("woff2"), url("assets/fonts/6034527/ea4bc757-7065-44bc-91fa-ffe8933ce21b.woff") format("woff");
}

@font-face {
    font-family: "Kallisto W05 Thin Italic";
    src: url("assets/fonts/6034531/78b0ef80-7537-42d4-a469-e1eeb4ae2cb2.woff2") format("woff2"), url("assets/fonts/6034531/c04bc0e0-4ead-4b90-a861-4646661446f3.woff") format("woff");
}

@font-face {
    font-family: "Kallisto W05 Medium";
    src: url("assets/fonts/6034543/33dfc67f-335b-4cf1-8741-63e5f0944e5c.woff2") format("woff2"), url("assets/fonts/6034543/89a4bec6-cf70-4542-821a-0a738a41f438.woff") format("woff");
}

@font-face {
    font-family: "Kallisto W05 Medium Italic";
    src: url("assets/fonts/6034547/de3fe9b1-7269-4416-9f38-1ba9a236f94d.woff2") format("woff2"), url("assets/fonts/6034547/d2d8a865-8157-4079-ac4e-3b7cd841c57d.woff") format("woff");
}

@font-face {
    font-family: "Kallisto W05 Heavy";
    src: url("assets/fonts/6034559/fd608800-d6de-4fb7-b50d-84168d2f6d27.woff2") format("woff2"), url("assets/fonts/6034559/2d2ab8b9-476b-4981-91a5-86c74c7e5d3f.woff") format("woff");
}

@font-face {
    font-family: "Kallisto W05 Heavy Italic";
    src: url("assets/fonts/6034563/47a7583c-c150-4538-9938-f464ba16a026.woff2") format("woff2"), url("assets/fonts/6034563/9fe0ae5b-2017-4140-aeea-a01d5000212f.woff") format("woff");
}

body {

    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}

#outer-layout {
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
}

.image_logo{
    width: 100% !important;
}

button,
input[type="submit"],
input[type="reset"] {
    background: none;
    color: #FFFFFF;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

p {
    color: white;
}

#pagewrapper {
    padding-left: 20px;
    padding-right: 20px;
}

.two-col-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 980px;
    margin: auto;
    gap: 100px;
}

.infobox {
    font-size: 0.8em;
    border: 1px solid white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    margin: 20px 0px;
    display: none;
}

.dramatic-header {
    font-family: "Kallisto W05 Heavy Italic";
    text-transform: uppercase;
}

a {
    text-decoration: none;
}

.p-dialog-content{
  border-top: 1px solid white;
  margin-top: 10px;
}

.p-dialog-mask{
    z-index: 2;
}

.site-map-links {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
}

.footer_align {
    vertical-align: bottom;
    text-align: right;
    margin-top: 12px;
}

.foot_logo {
    width: 50px;
    height: 50px;
    text-align: center !important;
    margin: 10px 0 0 0;
}

.button {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: auto;
    min-width: 300px;
    height: 56px;
    left: calc(50% - 426px/2);
    top: 450px;
    background-color: #0094FF;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    box-shadow: 0px 0px 0px 0px rgba(0, 149, 255, 0);
    transition: background-color, box-shadow, 1s;
}

.p-message .p-message-wrapper {
    padding: 1.25rem 1.75rem;
}

.p-message-wrapper {
    display: flex;
    align-items: center;
}

.p-component,
.p-component * {
    box-sizing: border-box;
}

.p-message.p-message-error {
    background: #ffe1e0;
    border: solid #ff5757;
    border-width: 0 0 0 6px;
    color: #2c1e30;
}

.p-message {
    margin: 1rem 0;
    border-radius: 6px;
}

.p-message-close-icon {
    color: #2c1e30;
}

.p-message.p-message-error .p-message-icon {
    color: #ff5757;
}

.p-message .p-message-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
}

.p-message .p-message-summary {
    font-weight: 600;
    font-size: 18px;
}

.p-message .p-message-detail {
    margin-left: 0.5rem;
    font-size: 16px;
}

.pi {
    font-family: primeicons;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.button:hover {
    background-color: #50b6ff;
    -webkit-box-shadow: 0px 0px 45px 4px rgba(0, 149, 255, 0.9);
    -moz-box-shadow: 0px 0px 45px 4px rgba(0, 149, 255, 0.9);
    box-shadow: 0px 0px 45px 4px rgba(0, 149, 255, 0.9);
}

.show-spinner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    padding: 250px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 9999;
}

.button_margin {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: auto;
    min-width: 300px;
    height: 56px;
    left: calc(50% - 426px/2);
    top: 450px;
    margin-top: 10px;
    background: #0094FF;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
}

.blacklist {
    width: 100%;
    height: auto;
    left: 376px;
    top: 407px;
    font-weight: 400;
    font-size: 26px;
    line-height: 42px;
    text-align: center;
    color: #FFFFFF;
    overflow: hidden;
}

.buttonpop {
    flex-direction: row;
    justify-content: center;
    width: auto;
    min-width: 300px;
    height: 56px;
    left: calc(50% - 426px/2);
    top: 450px;
    padding: 10px 60px 10px 60px;
    background: #0094FF;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
}

.button_connect {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: auto;
    min-width: 300px;
    height: 56px;
    left: calc(50% - 426px/2);
    top: 450px;
    background: #ffffff;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    filter: drop-shadow(0px 0px 100px rgba(0, 148, 255, 0.5));
}

.right_align {
    text-align: center;
}

.center_align {
    text-align: center;
}

.dramatic-header {
    top: 45px;
    font-weight: 500;
    font-size: 35px;
    color: #FFFFFF;
    text-shadow: 0px 0px 15px #0094FF;
}

.information {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    margin: 15px 0 10px 0;
}

.protens-button {
    background-color: #ed581b;
    color: white;
    text-decoration: none;
    font-weight: bold;
    padding: 10px 10px;
    transition: background-color, box-shadow, 1s;
    border-radius: 10px;
    margin: 20px auto;
    display: block;
    max-width: 330px;
    box-shadow: 0px 0px 0px 0px rgba(0, 149, 255, 0);
}

.protens-button:hover {
    color: white;
    -webkit-box-shadow: 0px 0px 16px 4px rgba(209, 137, 69, 0.9);
    -moz-box-shadow: 0px 0px 16px 4px rgba(209, 137, 69, 0.9);
    box-shadow: 0px 0px 16px 4px rgba(209, 137, 69, 0.9);
}

/* #theUnioverse */

.theunioverse {
    width: 140px;
    height: 14px;
    left: 92.5px;
    top: 30.79px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 104%;
    /* or 19px */
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 0.91);
}

/* Rectangle 13 */

.theunioverse_rec {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 250px;
    height: 80px;
    left: calc(50% - 426px/2);
    top: 450px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    background-color: #448abe;
    box-shadow: 0px 0px 0px 0px rgba(0, 149, 255, 0);
    transition: background-color, box-shadow, 1s;
    border-radius: 10px;
}

.theunioverse_rec:hover {
    background-color: #5f9fce;
    -webkit-box-shadow: 0px 0px 16px 4px rgba(95, 160, 206, 0.9);
    -moz-box-shadow: 0px 0px 16px 4px rgba(95, 160, 206, 0.9);
    box-shadow: 0px 0px 16px 4px rgba(95, 160, 206, 0.9);
}


.fbsize {
    width: 50px;
    height: 30px;
    vertical-align: middle;
}

.theunioverse_fb {

    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 250px;
    height: 80px;
    left: calc(50% - 426px/2);
    top: 450px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 10px;
    background-color: #6E82EC;
    transition: background-color, box-shadow, 1s;
}

.theunioverse_fb:hover {
    background-color: #8c9df0;
    -webkit-box-shadow: 0px 0px 16px 4px rgba(110, 131, 236, 0.9);
    -moz-box-shadow: 0px 0px 16px 4px rgba(110, 131, 236, 0.9);
    box-shadow: 0px 0px 16px 4px rgba(110, 131, 236, 0.9);
}

.create_account {

    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: auto;
    min-width: 300px;
    height: 65px;
    left: calc(50% - 426px/2);
    top: 450px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    background-color: #0094FF;
    box-shadow: 0px 0px 0px 0px rgba(0, 149, 255, 0);
    transition: background-color, box-shadow, 1s;
}

.create_account:hover {
    background-color: #50b6ff;
    -webkit-box-shadow: 0px 0px 45px 4px rgba(0, 149, 255, 0.9);
    -moz-box-shadow: 0px 0px 45px 4px rgba(0, 149, 255, 0.9);
    box-shadow: 0px 0px 45px 4px rgba(0, 149, 255, 0.9);
}

.social_media {
    margin-top: 50px;
    display: flex;
    gap: 1.5rem;
}

.social_media_expired {
    margin-top: 50px;
    display: flex;
    gap: 1.5rem;
    justify-content: center;
}

.twitter_style_content {
    font-size: 25px;
    vertical-align: bottom;
}

.twitter_style {
    font-size: 25px;
    vertical-align: top;
}

.twitter_style_fb {
    font-size: 25px;
    vertical-align: middle;
}

.addModal {
    background-color: rgb(0, 0, 0) !important;
    padding: 30px;
    border: 2px solid white;
}

/*Footer logo*/
.text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}

svg {
    width: 100%;
    height: 100%;
}

.site-footer {
    position: absolute;
    bottom: 0px;
    height: 220px;
    width: 100%;
}

@media only screen and (max-width: 940px) {
    .site-footer {
        height: 300px;
    }
}

.site-footer .logo {
    -webkit-filter: drop-shadow(0 0 0.5rem #0080ff) drop-shadow(0 0 1rem #0080ff) !important;
    filter: drop-shadow(0 0 0.5rem #0080FF) drop-shadow(0 0 1rem #0080FF) !important;
    text-align: center;
}

.w-20 {
    width: 5rem;
}

.login_axis {
    margin-top: 5px;
}

.h-20 {
    height: 5rem;
}

.mb-8 {
    margin-bottom: 2rem;
}

.text-blueGray-500 {
    --tw-text-opacity: 1;
    color: rgba(100, 116, 139, var(--tw-text-opacity));
}

.text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}

a {
    text-decoration: none;
}

.border-t-blueGray-700 {
    --tw-border-opacity: 1;
    border-top-color: rgba(51, 65, 85, var(--tw-border-opacity));
}

.border-transparent {
    border-color: rgba(0, 0, 0, 0);
}

.gap-y-1 {
    row-gap: 0.25rem;
}

.gap-x-4 {
    -webkit-column-gap: 1rem;
    -moz-column-gap: 1rem;
    column-gap: 1rem;
}

.justify-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.flex-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.mb-4 {
    margin-bottom: 1rem;
}

ol,
ul {
    list-style: none;
}

fieldset,
ol,
ul {
    margin: 0;
    padding: 0;
}

ol,
ul {
    box-sizing: border-box;
}

.py-16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.text-blueGray-400 {
    --tw-text-opacity: 1;
    color: rgba(148, 163, 184, var(--tw-text-opacity));
}

.text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
}

.text-center {
    text-align: center;
}

.foot_bar_logo {
    width: 60px;
    height: 60px;
}

@media screen and (max-width: 940px) {
    .two-col-wrapper {
        grid-template-columns: 1fr;
        gap: 0px;
    }

    .dramatic-header {
        font-weight: 500;
        font-size: 28px;
        line-height: 38px;
        color: #FFFFFF;
        text-shadow: 0px 0px 15px #0094FF;
        margin: 0 0 15px 0;
    }

    .dramatic-header {
        font-family: "Kallisto W05 Heavy Italic";
        text-transform: uppercase;
    }

    a.social_media_links {
        text-decoration: none;
        color: #FFFFFF;
    }

    a.social_media_links:hover {
        text-decoration: none;
        color: #FFFFFF;
    }

    .blacklist_mobile {
        height: auto;
        left: 376px;
        top: 407px;
        font-weight: 400;
        font-size: 16.5px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
    }

    .addModal_mobile {
        background-color: rgb(0, 0, 0) !important;
        padding: 30px;
        border: 2px solid white;
    }

    .buttonpop_mobile {
        flex-direction: row;
        justify-content: center;
        width: auto;
        min-width: 300px;
        height: 56px;
        left: calc(50% - 426px/2);
        top: 450px;
        padding: 10px 60px 10px 60px;
        background: #0094FF;
        border-radius: 10px;
        font-size: 18px;
        font-weight: 600;
    }

    /*Footer logo*/
    .text-white {
        --tw-text-opacity: 1;
        color: rgba(255, 255, 255, var(--tw-text-opacity));
    }

    svg {
        width: 100%;
        height: 100%;
    }

    .site-footer .logo {
        -webkit-filter: drop-shadow(0 0 0.5rem #0080ff) drop-shadow(0 0 1rem #0080ff) !important;
        filter: drop-shadow(0 0 0.5rem #0080FF) drop-shadow(0 0 1rem #0080FF) !important;
    }

    .w-20 {
        width: 5rem;
    }

    .login_axis {
        margin-top: 4px;
    }

    .h-20 {
        height: 5rem;
    }

    .mb-8 {
        margin-bottom: 2rem;
    }

    .text-blueGray-500 {
        --tw-text-opacity: 1;
        color: rgba(100, 116, 139, var(--tw-text-opacity));

    }

    .text-white {
        --tw-text-opacity: 1;
        color: rgba(255, 255, 255, var(--tw-text-opacity));
    }

    a {
        text-decoration: none;
    }

    .border-t-blueGray-700 {
        --tw-border-opacity: 1;
        border-top-color: rgba(51, 65, 85, var(--tw-border-opacity));
    }

    .border-transparent {
        border-color: rgba(0, 0, 0, 0);
    }

    .gap-y-1 {
        row-gap: 0.25rem;
    }

    .gap-x-4 {
        -webkit-column-gap: 1rem;
        -moz-column-gap: 1rem;
        column-gap: 1rem;
    }

    .justify-center {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .items-center {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .flex-wrap {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .flex {
        display: -webkit-box;
        display: -ms-flexbox;
        display: block;
    }

    .mb-4 {
        margin-bottom: 1rem;
    }

    ol,
    ul {
        list-style: none;
    }

    fieldset,
    ol,
    ul {
        margin: 0;
        padding: 0;
    }

    ol,
    ul {
        box-sizing: border-box;
    }

    .py-16 {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .bg-black {
        --tw-bg-opacity: 1;
        background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    }

    .text-blueGray-400 {
        --tw-text-opacity: 1;
        color: rgba(148, 163, 184, var(--tw-text-opacity));
    }

    .text-sm {
        font-size: .875rem;
        line-height: 1.25rem;
    }

    .text-center {
        text-align: center;
    }

    .social_media_invalid {
        margin-top: 20px;
        justify-content: center;
    }

    /* #theUnioverse */

    .theunioverse {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        width: auto;
        min-width: 300px;
        height: 70px;
        left: calc(50% - 426px/2);
        top: 450px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #55ACEE;
        border-radius: 10px;
        font-size: 18px;
        font-weight: 600;
    }

    /* Rectangle 13 */

    .theunioverse_rec {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        width: 300px;
        height: 80px;
        left: calc(50% - 426px/2);
        top: 450px;
        border-radius: 10px;
        font-size: 18px;
        font-weight: 600;
        background-color: #448abe;
        box-shadow: 0px 0px 0px 0px rgba(0, 149, 255, 0);
        transition: background-color, box-shadow, 1s;
    }

    .theunioverse_rec:hover {
        background-color: #5f9fce;
        -webkit-box-shadow: 0px 0px 16px 4px rgba(95, 160, 206, 0.9);
        -moz-box-shadow: 0px 0px 16px 4px rgba(95, 160, 206, 0.9);
        box-shadow: 0px 0px 16px 4px rgba(95, 160, 206, 0.9);
    }

    .theunioverse_fb {

        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        width: 300px;
        height: 80px;
        left: calc(50% - 426px/2);
        top: 450px;
        border-radius: 10px;
        font-size: 18px;
        font-weight: 600;
        background-color: #6E82EC;
        transition: background-color, box-shadow, 1s;
    }

    .theunioverse_fb:hover {
        background-color: #8c9df0;
        -webkit-box-shadow: 0px 0px 16px 4px rgba(110, 131, 236, 0.9);
        -moz-box-shadow: 0px 0px 16px 4px rgba(110, 131, 236, 0.9);
        box-shadow: 0px 0px 16px 4px rgba(110, 131, 236, 0.9);
    }

    .create_account {

        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        width: auto;
        min-width: 300px;
        height: 65px;
        left: calc(50% - 426px/2);
        top: 450px;
        border-radius: 10px;
        font-size: 18px;
        font-weight: 600;
        background: #0094FF;
    }

    .fbsize {
        width: 35px;
        height: 30px;
        vertical-align: middle;
    }
}

#hexframe {
    background-image: url('./assets/images/hex-edge-l.svg'), url('./assets/images/hex-edge-r.svg');
    background-position: top left, top right;
    background-size: 200px, 200px;
    background-repeat: repeat-y, repeat-y;
}

@media screen and (max-width: 420px) {
    #hexframe {
        background-image: url('./assets/images/hexbg.svg');
        background-position: top left;
        background-size: 200px;
        background-repeat: repeat;
    }
}

.event-title {
    text-align: center;
    color: white;
    font-family: "Kallisto W05 Medium";
    font-size: 2em;
    text-transform: uppercase;
}

@media (max-width: 940px) {
    .event-title {
        margin-bottom: 30px;
    }

    .show-spinner {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0%;
        left: 0%;
        right: 0%;
        bottom: 0%;
        padding: 250px;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.9);
        z-index: 9999;
    }

}